import { TextField } from "@rmwc/textfield";
import { graphql, navigate } from "gatsby";
import React from "react";
import { HeroLayout } from "../components/hero-layout";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { LandingApiClient } from "../imports/landing/types/LandingApiClient";
import { rhythm } from "../utils/typography";
import { getQueryParam } from "../utils/query";
import { captureException } from "../components/sentry";
import { Button } from "@rmwc/button";

class VerifyEmail extends React.Component {
  state = {
    // user either clicks on a link in email which has ?token=...
    // or user enters 4 digits code
    code: "",

    verifyWithToken: false,

    error: null,
  };

  componentDidMount() {
    const tokenFromURL = getQueryParam("token");
    if (tokenFromURL) {
      this.setState({ verifyWithToken: true });
      this.processSignUp({ emailedToken: tokenFromURL });
    } else {
      if (!this.props.location.state || !this.props.location.state.email) {
        captureException(
          new Error('Navigated to "verify-email" without email in state')
        );
        navigate("/error/");
      } else {
        console.info("Email from state: ", this.props.location.state.email);
      }
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.processSignUp({
      emailAndCode: {
        email: this.props.location.state.email,
        code: this.state.code,
      },
    });
  };

  processSignUp(signUpRequest) {
    const api = new LandingApiClient(`${process.env.API_URL}/api`);

    api
      .verifyEmail(signUpRequest)
      .then((res) => {
        if (res.status === "success") {
          return navigate("/signup/", { state: { orgSignup: res.signup } });
        } else if (res.status === "expired") {
          this.setState({
            error:
              "Code has expired. Please start email verification process from scratch.",
          });
        } else if (res.status === "invalid") {
          this.setState({
            error:
              "Code is invalid. Please check that you are using correct code or start email verification process from scratch.",
          });
        } else {
          captureException(
            new Error("VerifyEmail unknown status: " + res.status)
          );

          this.setState({
            error: "Unexpected error. Let's try again?",
          });
        }
      })
      .catch((e) => {
        captureException(e);
        this.setState({
          error: "Unexpected error. Let's try again?",
        });
      });
  }

  render() {
    if (this.state.verifyWithToken && !this.state.error) return null;

    return (
      <Layout withMaxWidth={false}>
        <SEO />
        <HeroLayout>
          <h1 className="title" style={{ marginBottom: rhythm(1 / 2) }}>
            Enter a security code
          </h1>

          <h2
            className="subtitle"
            style={{ marginTop: 0, marginBottom: rhythm(1) }}
          >
            A security code has been sent to your email
          </h2>
          <div className={`sign-up-form sign-up-form--align-left`}>
            <form
              onSubmit={this.handleSubmit}
              style={{ marginBottom: rhythm(1 / 2) }}
            >
              <div className="email-field">
                <TextField
                  outlined
                  name="code"
                  required
                  label="Security code"
                  type="text"
                  value={this.state.code}
                  onChange={this.handleInputChange}
                />
                <Button unelevated type="submit">
                  Continue
                </Button>
              </div>
            </form>
            {/*// TODO: A11Y*/}
            {this.state.error && (
              <p className="mdc-typography--body2">
                <span className="error">
                  {this.state.error} If the problem persists, contact{" "}
                  <a href={"mailto:support@remeet.com"}>support@remeet.com</a>.
                </span>
              </p>
            )}
          </div>
        </HeroLayout>
      </Layout>
    );
  }
}

export default VerifyEmail;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
